import React, { useRef, useState } from 'react'
import { graphql } from 'gatsby'
import { getLocaleMarket } from '../utils/getLocaleMarket'
import { getLocale } from '../utils/localeUtils'
import { BioHeroImage } from '../components/shared/molecules/BioHeroImage'
import { Search } from '../components'
import { Seo } from '../components/base'
import { getPageBuilderComponents } from '../components/base/PageBuilderComponents'
import { NewsGrid } from '../components/news/NewsGrid'
import { SinglePage } from './SinglePage'

const NewsPage = ({ data, location, pageContext }) => {
  const [filterIsTriggered, setFilterIsTriggered] = useState(false)
  const languages = { pageContext }

  const { title, pageId, seo, image, imageMobile, pageBuilder, isGridHidden } =
    data.sanityNewsPage

  const { _rawNoSearchResultsText } = data.sanityOfferPage
  let myMarket = getLocaleMarket()

  const news = data.allSanityNews.nodes
  const sanityMarkets = data.allSanityMarket.nodes

  const searchResultRef = useRef()
  const locale = getLocale()

  const isPressePage = location.pathname === '/presse'
  const isPressemitteilungen = title === 'Pressemitteilungen'

  return (
    <>
      <SinglePage
        heroImage={image}
        heroImageMobile={imageMobile}
        translations={languages}
        title={title}>
        <Seo
          title={seo?.metaTitle}
          description={seo?.metaDescription}
          image={seo?.ogImage?.asset?.url}
        />
        <section className="md:mx-auto">
          {image ? <h1 className="text-center">{title}</h1> : null}
          {pageBuilder.map((item, index) => {
            const component = getPageBuilderComponents(
              { ...item, pageId: pageId, isCallFromAfterGrid: false },
              myMarket
            )
            return <React.Fragment key={index}>{component}</React.Fragment>
          })}
        </section>
        {!isGridHidden ? (
          <div
            ref={searchResultRef}
            className="flex flex-wrap justify-between relative">
            <Search
              showToTopButton
              noInitialRender
              index="news"
              initialItems={news}
              count={data.allSanityNews.totalCount}
              searchListHeight={searchResultRef?.current?.offsetHeight}
              locale={locale}
              setFilterIsTriggered={setFilterIsTriggered}>
              <NewsGrid
                items={news}
                noSearchResultsText={_rawNoSearchResultsText}
                sanityMarkets={sanityMarkets}
                showNewsTicker={isPressePage}
                isPressemitteilungen={isPressemitteilungen}
              />
            </Search>
          </div>
        ) : null}
        <section className="md:mx-auto">
          {pageBuilder
            .filter(item => item.isAfterGrid == true)
            .map((item, index) => (
              <React.Fragment key={index}>
                {getPageBuilderComponents(
                  { ...item, pageId: pageId, isCallFromAfterGrid: true },
                  myMarket
                )}
              </React.Fragment>
            ))}
        </section>

        {/* </LayoutBackground> */}
        {/* </Layout> */}
      </SinglePage>
    </>
  )
}

export default NewsPage

export const newsQuery = graphql`
  query ($id: String) {
    sanityNewsPage(_id: { eq: $id }) {
      isGridHidden
      title
      desc
      pageId: id
      _rawIntroduction
      usedWithNewBioDivider
      fullWidthHeader
      image {
        ...ImageObjectQuery
      }
      imageMobile {
        ...ImageQuery
      }
      icon {
        ...ImageObjectQuery
      }
      titleIcon {
        ...ImageObjectQuery
      }
      ...NewsPagePageBuilderElements
    }
    allSanityMarket {
      nodes {
        name
        openingDay
        openingDelayed
        image {
          ...ImageObjectQuery
        }
        address {
          street
          zip
          city
        }
        countryCode
        status
      }
    }
    allSanityNews {
      nodes {
        title
        pageId: id
        newsMetaInfo {
          releaseDate
          updateDate
        }
        category {
          id
          title
          slug
          parent: parentCategory {
            id
            title
            slug
          }
        }

        desc
        externalUrl
        tags {
          ... on SanityNewsTag {
            title
            slug
          }
        }
        slug: slug {
          current
        }
        image {
          ...ImageObjectQuery
        }
        previewImage {
          ...ImageObjectQuery
        }
        ...NewsPageBuilderElements
      }
      totalCount
    }
    sanityOfferPage {
      _rawNoSearchResultsText
    }
  }
`
