import React from 'react'
import { BioNoResults } from '../shared'

import * as styles from './../../css/molecules/newsGrid.module.scss'
import { NewsGridPreviewCard } from './NewsGridPreviewCard'
import classNames from 'classnames'
import { NewsTicker } from './NewsTicker'
import { useSearchStore } from '../../utils'
import useIsMobileDetect from '../../utils/useIsMobileDetect'

export const NewsGrid = ({
  items,
  noSearchResultsText,
  sanityMarkets,
  showNewsTicker,
  isPressemitteilungen
}) => {
  if (items?.length === 0) return <BioNoResults content={noSearchResultsText} />
  const searchTerm = useSearchStore(state => state.searchTerm['news'])

  const isMobile = useIsMobileDetect(904)

  const filteredItems = isPressemitteilungen
    ? items.filter(post => !post.externalUrl)
    : items

  const initialNews = filteredItems.slice(0, isMobile ? 1 : 2)
  const remainingNews = filteredItems.slice(isMobile ? 1 : 2)

  return (
    <div className={classNames(styles.newsGrid, 'newCards')}>
      {initialNews.map((post, index) => (
        <NewsGridPreviewCard post={post} key={`post_${index}`} index={index} />
      ))}
      {showNewsTicker && !searchTerm ? (
        <NewsTicker sanityMarkets={sanityMarkets} key="newsticker" />
      ) : null}

      {remainingNews.map((post, index) => (
        <NewsGridPreviewCard post={post} key={`post_${index}`} index={index} />
      ))}
    </div>
  )
}
